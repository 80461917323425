import { ImImage } from "react-icons/im";
import { Carousel } from "react-responsive-carousel";
import React from "react";
import { MetadataState } from "../store";
import { useSnapshot } from "valtio";

const columnize = (array, cols, skip = null) => {
  if (!(array instanceof Array)) {
    return;
  }
  if (skip instanceof Array) {
    array = array.filter((elm) => !skip.includes(elm));
  }
  return array.reduce((acc, val, idx) => {
    if (!(idx % cols)) {
      acc.push([]);
    }
    acc[acc.length - 1].push(val);
    return acc;
  }, []);
};

const columnOrder = [
  "brand",
  "name",
  "id",
  "rsk",
  "nrf",
  "lvi",
  "overallLength",
  "pipeConnection",
  "mainsConnection",
  "fluidTemperatureRange",
  "efficiency",
];

const formatRSK = (rsk) => {
  rsk += "";
  return (
    rsk.substring(0, 3) + " " + rsk.substring(3, 5) + " " + rsk.substring(5, 7)
  );
};

const formatEfficiency = (eei, mei, efficiencyIndex) => {
  let efficiencyArray = [];

  if (efficiencyIndex) efficiencyArray.push(efficiencyIndex);
  if (eei) efficiencyArray.push("EEI ≤ " + eei);
  if (mei) efficiencyArray.push("MEI ≥ " + mei);

  return efficiencyArray.join(", ");
};

const formatFluidTemperatureRange = (
  fluidTemperatureMin,
  fluidTemperatureMax
) => {
  if (fluidTemperatureMin && fluidTemperatureMax)
    return fluidTemperatureMin + " °C  – " + fluidTemperatureMax + " °C";
  if (fluidTemperatureMin) return "≥ " + fluidTemperatureMin + " °C";
  if (fluidTemperatureMax) return "≤ " + fluidTemperatureMax + " °C";
  return "";
};

// Should be global state
const countryCode = "DE";

const transformPump = (originalPump, MetadataSnap) => {
  const pump = {
    name: originalPump.name,
  };

  if (originalPump.id[0] !== "X") pump.id = originalPump.id;
  // we will use csi concept instead, hence I comment out the following
  // if (
  //   originalPump.id[0] !== "X" &&
  //   originalPump.rsk &&
  //   MetadataSnap.country === "SE"
  // )
  //   pump.rsk = formatRSK(originalPump.rsk);
  if (originalPump.overallLength)
    pump.overallLength = originalPump.overallLength + " mm";
  if (originalPump.mainsConnection)
    pump.mainsConnection = originalPump.mainsConnection;
  if (originalPump.pipeConnection)
    pump.pipeConnection = originalPump.pipeConnection;
  if (originalPump.brand) pump.brand = originalPump.brand;
  if (originalPump.eei || originalPump.mei || originalPump.efficiencyIndex)
    pump.efficiency = formatEfficiency(
      originalPump.eei,
      originalPump.mei,
      originalPump.efficiencyIndex
    );
  if (originalPump.fluidTemperatureMin || originalPump.fluidTemperatureMax)
    pump.fluidTemperatureRange = formatFluidTemperatureRange(
      originalPump.fluidTemperatureMin,
      originalPump.fluidTemperatureMax
    );
  if (originalPump.distributor && originalPump.distributor[countryCode])
    pump.distributor = originalPump.distributor[countryCode];

  // keep in mind the distinction that for this function originalPump is the argument
  // and pump is the retuned object, we can call it output pump for clarity
  // if originalPump's csi field contains a key for the current country,
  // we can use it to find out csi name and csi number for the output pump
  // then we can create a field for the output pump with the csi name, eg. pump.nrf or pump.rsk
  // and the vallue of that field will be the csi number
  if (
    originalPump.csi &&
    Object.keys(originalPump.csi).includes(MetadataSnap.country)
  ) {
    const csi = originalPump.csi[MetadataSnap.country];
    const csiName = Object.keys(csi)[0];
    const csiNumber = Object.values(csi)[0];
    pump[csiName] = formatRSK(csiNumber);
    // console.log(`${pump.name} ${csiName} : ${csiNumber}`);
  }
  return pump;
};

export const Pump = (props) => {
  const MetadataSnap = useSnapshot(MetadataState);

  if (typeof props?.pump?.name !== "string") return null;

  const pump = transformPump(props.pump, MetadataSnap);
  const translations = props.translations || {};
  const title = props.title || "";
  const columns = typeof props.columns === "number" ? props.columns : 2;
  const images = props.images instanceof Array ? props.images : null;
  let skipPumpFields = [
    "image",
    "comments",
    "adapters",
    "replacementType",
    "type",
  ];
  // get rid of comments that are empty strings,
  // comments of empty strings are returned from backend in case that comment is not translated to language in use
  const comments =
    props.comments && props.comments.filter((comment) => comment !== "");
  return (
    <div className="card pump-card">
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{title}</p>
            <p className="subtitle is-6">
              <b>{pump?.brand}</b> {pump?.name}
            </p>
          </div>
        </div>

        <div className="content">
          <table>
            <tbody>
              {columnize(
                columnOrder.filter((column) =>
                  Object.keys(pump).includes(column)
                ),
                columns,
                skipPumpFields
              ).map((col, idx) => {
                return (
                  <tr key={idx}>
                    {col.map((attribute, subidx) => {
                      return (
                        <React.Fragment key={`row_${subidx}`}>
                          <th>
                            <div>{translations?.[attribute]}</div>
                          </th>
                          <td>
                            <div>{pump?.[attribute]}</div>
                          </td>
                        </React.Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {images && (
            <div className="image-container">
              {images.length < 1 ? (
                <ImImage className="no-image" />
              ) : images.length === 1 ? (
                <img src={images[0]} alt="Pump" className="productImg" />
              ) : (
                <Carousel showIndicators={false} showStatus={false}>
                  {images.map((image, index) => (
                    <div>
                      <img key={index} src={image} alt="Pump" />
                    </div>
                  ))}
                </Carousel>
              )}
            </div>
          )}
        </div>
        {comments && (
          <section className="commentSection">
            <div className="commentComment">
              {MetadataSnap.texts.labels.comments}
            </div>
            <div style={{ flexGrow: 1 }}>
              {comments.map((comment, idx) => (
                <div key={"comment-" + idx} className="commentComments">
                  {comment}
                </div>
              ))}
            </div>
          </section>
        )}
        {props.children && <div>{props.children}</div>}
      </div>
    </div>
  );
};
