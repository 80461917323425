import React from "react";
import logo from "./asset/logo-altech.svg";

import "./App.css";
import "./App.sass";
import "../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

import { BsExclamation } from "react-icons/bs";

import { SegmentedTabs, Tab } from "./components/SegmentedTabs";
import { Datasheet } from "./components/Datasheet";
import { Pump } from "./components/Pump";
import { Adapters } from "./components/Adapters";
import { Accessories } from "./components/Accessories";

import { LocaleState, MetadataState, PumpState, ViewState } from "./store";

import { useSnapshot } from "valtio";

import { Header } from "./components/Header";

import { Disclaimer } from "./components/Disclaimer";
import { CookieDisclaimer } from "./components/CookieDisclaimer";

import pump2Image from "./asset/Altech-pump2.png";

import { BrowserRouter, Switch, Route } from "react-router-dom";
const Home = () => {
  const MetadataSnap = useSnapshot(MetadataState);
  const LocaleSnap = useSnapshot(LocaleState);
  const PumpSnap = useSnapshot(PumpState);
  const ViewSnap = useSnapshot(ViewState);

  const transformPump = (pump) => {
    const newPump = {
      ...pump,
    };

    if (newPump.brand) {
      newPump.brand = MetadataSnap.brands[newPump.brand];
    }

    return newPump;
  };

  const columns = ViewSnap.width > 600 ? 2 : 1;
  return (
    <div className="App">
      <Header></Header>

      <CookieDisclaimer />
      <Pump
        pump={transformPump(PumpSnap.existingPump)}
        title={MetadataSnap.texts?.general?.existingPumpTable}
        translations={MetadataSnap.texts?.labels}
        columns={columns}
        style={{ breakBefore: "always" }}
      />

      {PumpSnap.replacementPumps &&
        PumpSnap.replacementPumps.map(transformPump).map((rp, idx) => {
          return (
            <Pump
              key={"replacement-pump-" + idx}
              pump={rp}
              title={MetadataSnap.texts.general.replacementPumpTable}
              translations={MetadataSnap.texts.labels}
              columns={columns}
              images={rp.image ? ["/api/file/" + rp.image] : [logo]}
              comments={rp.comments}
            >
              <SegmentedTabs
                className="segmented-tabs"
                wholesaler={
                  rp.wholesaler && rp.wholesaler[MetadataSnap.country]
                }
                pumpId={rp.id}
              >
                {rp.adapters && Object.values(rp.adapters).length > 0 ? (
                  <Tab name={MetadataSnap.texts.general.adapterKit}>
                    <Adapters adapters={rp.adapters} pumpId={rp.id} />
                  </Tab>
                ) : null}

                {LocaleSnap.language === "de" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=de"}
                    />
                  </Tab>
                ) : null}
                {LocaleSnap.language === "es" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=es"}
                    />
                  </Tab>
                ) : null}

                {LocaleSnap.language === "pt" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=pt"}
                    />
                  </Tab>
                ) : null}
                {LocaleSnap.language === "pl" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=pl"}
                    />
                  </Tab>
                ) : null}
                {(LocaleSnap.language === "nl" ||
                  LocaleSnap.language === "en") &&
                rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=en"}
                    />
                  </Tab>
                ) : null}

                {LocaleSnap.language === "sv" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=sv"}
                    />
                  </Tab>
                ) : null}
                {LocaleSnap.language === "no" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=no"}
                    />
                  </Tab>
                ) : null}
                {LocaleSnap.language === "fr" && rp.datasheet ? (
                  <Tab name={MetadataSnap.texts.general.productSheet}>
                    <Datasheet
                      src={"/api/file/" + rp.datasheet + "?language=fr"}
                    />
                  </Tab>
                ) : null}
                {LocaleSnap.language === "sv" &&
                  MetadataSnap.country === "SE" &&
                  rp.accessoryNums > 0 && (
                    <Tab name="Tillbehör">
                      <Accessories pumpId={rp.id} />
                    </Tab>
                  )}
              </SegmentedTabs>
            </Pump>
          );
        })}
    </div>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:countrycode?/:pumpname?/:id?">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
