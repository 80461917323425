import React from "react";
import { useEffect } from "react";
// logo
import { AiOutlineShoppingCart } from "react-icons/ai";
import { distributorClick } from "../api/userAnalytics";
import { SelectionState } from "../store";

export const WholesaleButton = (props) => {
  const wholesalerURL = props.wholesalerURL;
  const logoName = props.logoName;
  const pumpId = props.pumpId;
  const originalPumpId = SelectionState.selectedPump;
  //   const adapter = props.adapter;
  //   const accessoryId = props.accessoryId;
  let firstValue, secondValue;

  const ahlsellClick = (firstValue, secondValue) => {
    if (props.pumpClicked) {
      firstValue = pumpId;
      secondValue = originalPumpId;
      distributorClick(firstValue, secondValue);
    }
    // if (props.adapterClicked) {
    //   firstValue = adapter;
    //   secondValue = pumpId;
    //   adapterClick(firstValue, secondValue);
    // }
    // if (props.accessoryClicked) {
    //   firstValue = accessoryId;
    //   secondValue = pumpId;
    //   accessoryClick(firstValue, secondValue);
    // }
  };
  return (
    <div
      className="hideOnPrint wholesaleButtonDiv"
      style={
        props.mobileStyling
          ? props.mobileStyling
          : { width: "max-content", marginLeft: "auto" }
      }
    >
      <a
        href={wholesalerURL}
        target="_blank"
        rel="noreferrer"
        onClick={
          (e) => {
            ahlsellClick(firstValue, secondValue);
          }
          // !adapter
          //   ? (e) => distributorClick(pumpId, originalPumpId)
          //   : (e) => adapterClick(adapter, pumpId)
        }
        className="WholesaleButton "
      >
        <img className="wholesaleImg" src={`/api/file/${logoName}`} alt="" />
        <div style={{ display: "flex", alignItems: "center" }}>
          <AiOutlineShoppingCart className="cart" />
          {/* <p className="wholesaleText">Köp nu</p> */}
        </div>
      </a>
    </div>
  );
};
